import React from 'react';
import { navigate } from 'gatsby';
import loadable from '@loadable/component';
import { FAQ } from 'constants/navigation';
import Skeleton from 'components/cards/skeleton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';

import useStyles from './styles';

const BaseCard = loadable(() => import('components/cards/base-card'));

const FAQCard = () => {
  const styles = useStyles();

  return <BaseCard
          classes={styles}
          onCardClick={() => navigate(FAQ)}
          title="Frequently Asked Questions"
          description="As we get more questions and figure out the answers this is where they live."
          buttonMessage="FAQ"
          imageChild={
            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={faQuestionCircle} size="8x" className={styles.largeIcon}/>
            </div>
            }
          iconChild={
            <span className={styles.icon}>
              <FontAwesomeIcon icon={faQuestionCircle}/>
            </span>
          }
          fallback={<Skeleton/>}
          />;
};

export default FAQCard;

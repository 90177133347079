import React from 'react';
import { navigate } from 'gatsby';
import loadable from '@loadable/component';
import { SUPPORT_PROBLEM } from 'constants/navigation';
import Skeleton from 'components/cards/skeleton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug } from '@fortawesome/pro-solid-svg-icons';

import useStyles from './styles';

const BaseCard = loadable(() => import('components/cards/base-card'));

const ReportBugCard = () => {
  const styles = useStyles();

  return <BaseCard
          classes={styles}
          onCardClick={() => navigate(SUPPORT_PROBLEM)}
          title="Report A Problem Or Get Support"
          description="If you have a problem using our software, or have any questions we are happy to help."
          buttonMessage="Problems & Support"
          imageChild={
            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={faBug} size="8x" className={styles.largeIcon}/>
            </div>
            }
          iconChild={
            <span className={styles.icon}>
              <FontAwesomeIcon icon={faBug}/>
            </span>
          }
          fallback={<Skeleton/>}
          />;
};

export default ReportBugCard;

import React from 'react';
import { navigate } from 'gatsby';
import loadable from '@loadable/component';
import { SUPPORT_DOCS } from 'constants/navigation';
import Skeleton from 'components/cards/skeleton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons';

import useStyles from './styles';

const BaseCard = loadable(() => import('components/cards/base-card'));

const DocumentsCard = () => {
  const styles = useStyles();

  return <BaseCard
          classes={styles}
          onCardClick={() => navigate(SUPPORT_DOCS)}
          title="Company Documents"
          description="Company policies and Terms & Conditions"
          buttonMessage="Documents"
          imageChild={
            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={faFileAlt} size="8x" className={styles.largeIcon}/>
            </div>
            }
          iconChild={
            <span className={styles.icon}>
              <FontAwesomeIcon icon={faFileAlt}/>
            </span>
          }
          fallback={<Skeleton/>}
          />;
};

export default DocumentsCard;

import React from 'react';
import Faq from 'components/cards/faq';
import Problem from 'components/cards/report-problem';
import Documents from 'components/cards/documents';
import { Grid } from '@material-ui/core';

import { features } from 'gatsby-env-variables';

import useStyles from 'apputil/view-styles';

const SupportHomeView = () => {
  const styles = useStyles();

  const { faq = false } = features;

  return (
    <div className={styles.menucontent}>
      <Grid spacing={2}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
        {faq && <Grid item md={4} sm={6} xs={12}>
          <Faq />
        </Grid>}
        <Grid item md={4} sm={6} xs={12}>
          <Problem />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Documents />
        </Grid>
    </Grid>
  </div>
  );
};

export default SupportHomeView;
